<template>
  <v-container
    fluid
  >
    <v-card>
      <v-subheader>
        {{ $vuetify.lang.t('$vuetify.patient.informtion') }}
      </v-subheader>
      <v-card-text
        v-if="patient"
      >
        <v-row>
          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.name') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.patientName }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.common.gender') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ $vuetify.lang.t(`$vuetify.gender.${patient.gender.toLowerCase()}`) }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.common.identityCard') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.credentialNum }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.common.phone') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.phone }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.national') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.national }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.diseases') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.diseases }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.address') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{ patient.address }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.disabilityStatus') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  class="ml-2 black--text"
                >
                  {{$vuetify.lang.t(`$vuetify.disabilityStatus.${ snakeToCamel(patient.disabilityStatus.toLowerCase()) }`)}}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.contract.label') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  v-if="patient.contractId"
                  class="ml-2 black--text"
                >
                  {{ patient.contractName }}
                </span>
                <span
                  v-else
                  class="ml-2 black--text"
                >
                  -
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.institution') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  v-if="patient.institutionId"
                  class="ml-2 black--text"
                >
                  {{ patient.institutionName }}
                </span>
                <span
                  v-else
                  class="ml-2 black--text"
                >
                  -
                </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="3"
          >
            <v-row>
              <v-col
                cols="6"
              >
                <span
                >
                  {{ $vuetify.lang.t('$vuetify.patient.doctor') }} :
                </span>
              </v-col>

              <v-col
                cols="6"
              >
                <span
                  v-if="patient.doctorId"
                  class="ml-2 black--text"
                >
                  {{ patient.doctorName }}/{{ patient.doctorPhone }}
                </span>
                <span
                  v-else
                  class="ml-2 black--text"
                >
                  -
                </span>
              </v-col>
            </v-row>
          </v-col>

        </v-row>


      </v-card-text>

      <v-divider class="my-4"></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="info darken-1"
          @click="handleGoBack"
        >
          {{ $vuetify.lang.t('$vuetify.common.goBack') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { snakeToCamel } from '@/utils';

  export default {
    props: {
      id: {
        type: String,
        default: () => null
      }
    },

    data () {
      return {
        patient: null
      }
    },

    computed: {

      ...mapGetters({
        currentPatient: 'patient/currentPatient'
      })
    },

    created () {
      this.patient = null
      this.patientShow(this.id).then( () => {
        this.patient = this.currentPatient
      })
    },

    methods: {

      ...mapActions({
        patientShow: 'patient/show'
      }),

      snakeToCamel,

      handleGoBack () {
        this.$router.go(-1)
      }
    }
  }
</script>
